<template>
  <div class="hello">
    <h1>{{ msg }} {{ $route.params.id }}</h1>
    <p>This is the candidate form.</p>
    <router-view></router-view>
  </div>
</template>

<script>export default {
    name: 'CandidateForm',
    props: {
      msg: String
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
